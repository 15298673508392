.sidebarLayput {
  margin-left: 80px;
}

.propertiesPanel {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 600px;
  background: #393a46;
}

.wrapper {
  margin-left: 600px;
}

.mobileHeader {
  display: none;
}

.mobileNavIcons{
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
}

.mobileNavMenu {
  width: calc(100%/5);
  text-align: center;
  height: 65px;
  color: #fff;
}

.mobileNavMenu  a{
  color: #fff;
  display: flex;
  height: inherit;
  justify-content: center;
  align-items: center;
}

.selected {
  background: rgba(255,255,255, 0.3);
  border-top: 4px solid rgba(110, 73, 229, 0.7);
}

@media only screen and (max-width: 480px) {
  .sidebarLayput {
    margin-left: 0;
  }

  .mobileHeader{
    display: block;
    height: 65px;
    width: 100%;
    position: fixed;
    bottom:0;
    background: #333333;
    z-index: 10000;
  }
}

@media only screen and (min-width: 421px) and (max-width: 767px) {}

@media only screen and (min-width: 768px) and (max-width: 991) {}

@media only screen and (min-width: 992px) and (max-width: 1200) {}

@media only screen and (min-width: 1201px){}
