#cookies-consent {
    position: fixed;
    bottom: 20px;
    right: 20px;
    left: 20px;
    background-color: #111111;
    height: auto!important;
    max-width: 460px;
    padding: 20px;
    border-radius: 5px;
    
    p {
        color: white;
    }

    .actions {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;

        .btn, .btn + p {
            display: inline-block;

            a {
                text-decoration: underline;
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    #cookies-consent {
        width: 460px;
        bottom: 20px;
        right: 20px;
        left: auto;

        .actions {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
    }
}
