.customGPlusSignIn {
  display: inline-block;
  background: white;
  color: #444;
  display: flex;
  border-radius: 5px;
  border: thin solid lightgray;
  white-space: nowrap;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  .label {
    font-family: serif;
    font-weight: normal;
  }

  .icon {
    background: url("../../../assets/images/g-normal.png") transparent 5px 50%
      no-repeat;
    display: inline-block;
    vertical-align: middle;
    width: 42px;
    height: 42px;
    flex-grow: 0;
  }

  .buttonText {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    flex-grow: 1;
    text-align: center;
    margin-right: 42px;
  }
}
