.headerLeft {
  height: 100%;
  left: 0;
  width: 80px;
  max-width: 80px;
  background: #333333;
  border: none;
}

.headerContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.sidebarIcon {
  display: block;
  width: 100%;
}

.sidebarIcon a:hover,
.sidebarIcon span:hover {
  background: rgba(255,255,255, 0.3);
  cursor: pointer;
}

.selected {
  background: rgba(255,255,255, 0.3);
  border-right: 4px solid rgba(110, 73, 229, 0.7);
}

.navLogo {
  padding: 0!important;
  display: flex;
  align-items: center;
  float: none;
  width: 100%;
}

.navLogoA {
  max-width: none!important;
  text-align: center;
}



@media only screen and (max-width: 480px) {
  .headerLeft {
    display: none;
    height: 100%;
    top: 0;
    display: fixed;
    width: 100%;
  }
}

@media only screen and (min-width: 421px) and (max-width: 767px) {}

@media only screen and (min-width: 768px) and (max-width: 991) {}

@media only screen and (min-width: 992px) and (max-width: 1200) {}

@media only screen and (min-width: 1201px){}
