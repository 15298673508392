#auth-wrapper {
  height: 100%;
  background: #28cfd2;

  .auth-aside {
    width: 30%;
    height: 100%;
    float: left;
    position: fixed;
  }

  .card-wrapper {
    min-height: 100%;
    width: 70%;
    float: right;
    justify-content: center;
  }

  .card-inner {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    flex: unset;
  }

  .logo {
    position: absolute;
    height: 40px;
    left: 20px;
    top: 20px;
    z-index: 1000;
    display: none;
  }

  .cta-top-right {
    position: absolute;
    top: 30px;
    right: 40px;
    z-index: 5;
  }

  .cta-btn {
    border: 1px solid #ccc;
    padding: 0.5rem 1rem;
    border-radius: 1.5rem;
    margin-left: 1rem;
    
    &:hover {
      background: #28cfd2;
      border: 1px solid #28cfd2;
      color: #fff;
    }
  }
}

@media only screen and (min-width: 480px) {
  #auth-wrapper {
    .logo {
      display: block;
    }
  }
}

@media only screen and (max-width: 480px) {
  #auth-wrapper {
    .card-wrapper {
      width: 100%;
    }

    .card-inner {
      width: 90%;
    }

    .aside {
      width: 0%;
      height: 0%;
      float: left;
    }
  }
}
@media only screen and (max-width: 768px) and (min-width: 481px) {
  #auth-wrapper {
    .card-inner {
      width: 90%;
    }

    .aside {
      width: 40%;
      height: 100%;
      float: left;
    }
  }
}
@media only screen and (max-width: 991px) and (min-width: 769px) {
  #auth-wrapper {
    .card-inner {
      width: 70%;
    }

    .aside {
      width: 30%;
      height: 100%;
      float: left;
    }
  }
}
@media only screen and (max-width: 1200px) and (min-width: 991px) {
  #auth-wrapper {
    .card-inner {
      width: 70%;
    }
  }
}
