
.user-modal-cognito-detail-editable{

  input[type=text]{
  border: 1px gray solid;
  background-color: aliceblue;
  width: 300px;
  }

  i svg{
    margin-top: -4px;
    cursor: pointer;
  }

  button{
    background: none!important;
    border: none;
    padding: 0!important;
    cursor: pointer;
  }

}
