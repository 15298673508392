#aside {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: relative;

  .shadow {
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.3;
  }

  .comment {
    position: absolute;
    display: block;
    bottom: 20px;
    left: 20px;

    h3 {
      color: #fff;
      line-height: 1.5rem;
    }
    p {
      color: #fff;
      margin-bottom: 0;
      margin-top: 10px;
    }
    strong {
      color: #fff;
      margin-bottom: 0;
    }
  }
}
