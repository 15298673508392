:root {
  --primary: #39efa5;
}

body {
  background: white;
}

a {
  color: var(--purple);
}

.btn {
  height: auto;
}

span.has-error {
  color: var(--danger, red);
}

.page-container .main-content {
  min-height: auto;
}

.ant-slider {
  .ant-slider-handle {
    border-color: var(--primary);
  }

  .ant-slider-track {
    background-color: var(--primary);
  }
  .ant-slider-dot-active {
    border-color: lightgray;
  }
}

.ant-radio-wrapper {
  span {
    color: black;
  }

  .ant-radio {
    .ant-radio-inner {
      border-color: var(--purple);

      &::after {
        background-color: var(--purple);
      }
    }

    &.ant-radio-checked::after {
      border-color: var(--purple);
    }
  }
}

.ant-checkbox-wrapper {
  span {
    color: black;
  }

  .ant-checkbox {
    .ant-checkbox-inner {
      border-color: var(--purple);
    }

    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: var(--purple);
      }
    }
  }
}

.ant-input {
  &:focus,
  &:hover {
    border-color: var(--purple);
  }
  &:focus {
    box-shadow: 0 0 0 2px rgba(90, 51, 163, .2);
  }
}

.ant-divider-with-text-center {
  display: table;
  margin: 16px 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  background: transparent;

  &::before {
      position: relative;
      top: 50%;
      display: table-cell;
      width: 50%;
      border-top: 1px solid #e8e8e8;
      -webkit-transform: translateY(50%);
      -ms-transform: translateY(50%);
      transform: translateY(50%);
      content: '';
  }
  &::after {
      position: relative;
      top: 50%;
      display: table-cell;
      width: 50%;
      border-top: 1px solid #e8e8e8;
      -webkit-transform: translateY(50%);
      -ms-transform: translateY(50%);
      transform: translateY(50%);
      content: '';
  }
}

.spin {
  animation: rotation 1s infinite linear;
}

.float-left{
  float: left;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
