.oauth-callback {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .btn {
        height: auto;
    }
}
