.stats-card {
  .card-body {
    position: relative;
    overflow: hidden;
    padding: 25px;

    p {
      white-space: pre;
    }

    h2 {
      line-height: 1;
      margin: 0;
    }

    svg {
      position: absolute;
      right: 1px;
      top: 10px;
      width: 100px;
      height: 100px;
      opacity: 0.2;
    }
  }
}


@media only screen and (min-width: 768px) {
  .row.mb-0 .stats-card {
    margin-bottom: 0;
  }
}
