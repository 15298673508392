.modal {
    width: 100%;
    max-width: 80%;
    overflow: auto !important;
    top: 2vh;
  }
  
  .close {
    position: absolute;
    top: 32px;
    right: 32px;
    cursor: pointer;
    height: 22px;
    width: 22px;
  }
  
  @media only screen and (max-width: 768px) {
    .modal {
      top: 5vh;
    }
  }
  