.modal {
  width: 100%;
  max-width: 80%;
  max-height: 80%;
  overflow: auto !important;
  top: 2vh;
}

.close {
  position: absolute;
  top: 32px;
  right: 32px;
  cursor: pointer;
  height: 22px;
  width: 22px;
}

@media only screen and (max-width: 768px) {
  .modal {
    top: 5vh;
  }
}

.new-business-account-form{

  .new-business-account-form-actions{
    display: flex;
    justify-content: flex-end;
  }

  input.has-error{
    border: 1px solid #f5222d;
  }

  .new-business-account-form-address{
    line-height: normal;
  }

  /*to match bootstrap placeholder*/
  .form-control::placeholder{
    color: grey;
  }
}
